import './App.css';
import * as React from 'react';
import Button from '@mui/material/Button';
import Slider from "@mui/material/Slider";
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import TipsAndUpdatesTwoToneIcon from '@mui/icons-material/TipsAndUpdatesTwoTone';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import { useState } from "react";
import ReactDOM from 'react-dom';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const marks = [
  {
    value: 0,
    label: '0 sec',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 30,
    label: '30',
  },
  {
    value: 40,
    label: '40',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 60,
    label: '1 min',
  },
];

function valuetext(value) {
  return `${value} sec`;
}

function App() {
  const [color, setColor] = useState("");
  const [mode, setMode] = useState("");
  const [clear, setClear] = useState("");
  const [time, setTime] = useState("");
  const [message, setMessage] = useState("");
  var command= "";

  let handleSubmit = async (e) => {
    e.preventDefault();
    command = JSON.stringify({
      color:  color,
      mode: mode,
      clear: clear,
      time: time
    });
    
    fetch("https://signallight.propus.nl/light", {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: command,
      }).then((res) => {
        console.log("Response: ", res);
        if (res.status === 200) {
          setColor("");
          setMode("");
          setClear("");
          setTime("");
          setMessage("Succesfully submitted");
        } else {
          setMessage("Some error occured");
        }
        }).catch((err) => {
        console.log(err)
      });
  };
  

  return (

    <div className="App">

        <form onSubmit={handleSubmit}>
      <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
        >
          <Item>        
          <ToggleButtonGroup
            color="primary"
            value={color}
            exclusive
            onChange={(e) => setColor(e.target.value)}
          >
            <ToggleButton value="green"><FiberManualRecordIcon style={{ color: 'green' }} />Green</ToggleButton>
            <ToggleButton value="amber"><FiberManualRecordIcon style={{ color: 'orange' }} />Amber</ToggleButton>
            <ToggleButton value="red"><FiberManualRecordIcon style={{ color: 'red' }} />Red</ToggleButton>
          </ToggleButtonGroup>
          </Item>
          <Item>        
          <ToggleButtonGroup
            color="primary"
            value={mode}
            exclusive
            onChange={(e) => setMode(e.target.value)}
          >
            <ToggleButton value="on"><LightbulbIcon />On</ToggleButton>
            <ToggleButton value="blink"><TipsAndUpdatesTwoToneIcon />Blink</ToggleButton>
            <ToggleButton value="off"><LightbulbOutlinedIcon />Off</ToggleButton>
          </ToggleButtonGroup>
          </Item>
          <Item>
          <ToggleButtonGroup
            color="primary"
            value={clear}
            exclusive
            onChange={(e) => setClear(e.target.value)}
          >
            <ToggleButton value="1"><ClearIcon />Clear</ToggleButton>
            <ToggleButton value="0"><AddIcon />Add</ToggleButton>
            </ToggleButtonGroup>
          </Item>
          <Item>            
            <Slider
            aria-label="Always visible"
            defaultValue={5}
            getAriaValueText={valuetext}
            step={5}
            marks={marks}
            max={60}
            valueLabelDisplay="on"
            onChange={(e) => setTime(e.target.value)}
          />
          </Item>
          <Item><Button type="submit" variant="contained">Submit</Button></Item>
        </Stack>
        <div className="message">{message ? <p>{message}</p> : null}</div>
      </form>
    </div>
  );
}

export default App;
